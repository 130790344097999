<template>
  <b-card-code
    title="Create Warehouse"
  >
  <validation-observer
      ref="instansiForm"
      #default="{invalid}"
    >
    <b-form 
      @submit.prevent="created"
    >
      <b-row>
        <b-col 
          lg="6"
          md="6"
        >        
          <div role="group">
            <label for="input-live">{{ $t('Form.Instansi.Name') }}</label>
                <validation-provider
                    #default="{ errors }"
                    name="Name"
                    vid="name"
                    rules="required"
                >
                    <b-form-input
                        v-model="name"
                        id="mc-first-name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </div>
        </b-col>
        <b-col 
          lg="6"
          md="6"
        >
          <div role="group">
            <label for="input-live">{{ $t('Form.Instansi.Branding') }}</label>
          <validation-provider
                #default="{ errors }"
                name="Branding"
                vid="branding"
                rules="required"
            >
                <b-form-input
                    v-model="branding"
                    id="mc-branding"
                />
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </b-col>
        <b-col
          cols="12"
        >
            <div role="group">
                <label for="input-live">{{ $t('Form.Instansi.Description') }}</label>
                <b-form-textarea
                    id="notes"
                    v-model="description"
                    rows="2"
                    />
          </div>
        </b-col>
        <b-col
          cols="12"
        >         
            <b-card-text class="mb-0">
                {{ $t('Form.Instansi.Status') }}
            </b-card-text>
            <b-form-checkbox
                v-model="status"
                checked="true"
                class="custom-control-primary"
                name="check-button"
                switch
            />
        </b-col>

        <!-- submit and reset -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
          >
            {{ $t('Submit') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
          >
            {{ $t('Reset') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code'
import { required } from '@validations'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormRadioGroup, BFormTextarea, BCardText
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moduleApi from '@/store/module'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BCardText
  },
  directives: {
    Ripple,
  },
  data: () => ({
    status: '',
    name: '',
    branding: '',
    description: '',
    options: [
      { text: 'Active', value: '1' },
      { text: 'Inactive', value: '0' },
    ],
    required,
  }),
  methods: {
    created() { 
      this.$refs.instansiForm.validate().then(success => {
        if (success) {
          moduleApi.createInstansi({
            name: this.name,
            branding: this.branding,
            description: this.description,
            status: this.status
          })
            .then(response => {
                this.$router.replace({name: 'setups-instansi'})
            })
            .catch(error => {
              this.$refs.instansiForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
  setup(props) {
    const { t } = useI18nUtils()

    return {
      // i18n 
      t,
    }
  },
}
</script>
